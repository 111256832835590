<template>
  <div
    v-if="!hasNotified && ready"
    class="
      h-screen
      w-full
      cover
      fixed
      z-10
      top-0
      flex
      items-center
      justify-center
    "
  >
    <div
      class="
        bg-white
        h-2/5
        w-4/5
        rounded-3xl
        flex
        items-center
        justify-center
        relative
        flex-col
        relative
      "
    >
      <div class="absolute top-0 right-3 text-black" @click="closeCheckbox()">
        <mdicon name="close" width="30" height="30" />
      </div>
      <template v-if="payload.case == 1">
        <div class="text-secondary text-2xl mb-1">
          再次點擊 <span style="color: #ed257b">找好日</span> 按鈕
        </div>
        <div class="text-secondary text-2xl mb-10">即可切換回全貌月曆</div>
      </template>
      <template v-if="payload.case == 2">
        <div>
          <img class="" :src="payload.cardUrl" alt="" />
        </div>
      </template>
      <template v-else>
        <div
          class="text-primary text-2xl mb-1"
          :style="{ color: payload.color }"
        >
          {{ payload.text[0] }}
        </div>
        <div class="text-secondary text-2xl mb-1">{{ payload.text[1] }}</div>
        <div class="text-secondary text-2xl mb-10">{{ payload.text[2] }}</div>
      </template>

      <div class="text-checkbox text-xl absolute bottom-2">
        <label>
          <input type="checkbox" v-model="checkbox" />
          一週內不再顯示
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Util from "@/mixins/util";
export default {
  name: "notifyModal",
  mixins: [Util],
  props: ["popID", "payload"],
  data: () => ({
    ready: false,
    checkbox: false,
    hasNotified: false,
  }),
  async created() {
    let state = await this.getState(this.popID);
    this.hasNotified = state.hasNotified;
    this.ready = true;
  },
  methods: {
    closeCheckbox() {
      this.hasNotified = true;
      if (this.checkbox) {
        this.setState(this.popID);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cover {
  background-color: #00000073;
  z-index: 9999;
  .text-secondary {
    color: #333;
  }
  .text-checkbox {
    color: #999999;
    input[type="checkbox"] {
      position: relative;
      top: 2px;
      width: 18px;
      height: 18px;
    }
  }
}
</style>