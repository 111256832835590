<template>
  <div v-if="ready" class="h-screen">
    <div class="h-full bg-black">
      <div class="h-full flex flex-col text-white justify-center">
        <div class="search-block flex items-center">
          <div class="w-4/6 flex flex-col items-center">
            <div class="flex">
              <div class="word flex items-center justify-center">我</div>
              <div class="word flex items-center justify-center">的</div>
              <div class="word flex items-center justify-center">月</div>
              <div class="word flex items-center justify-center">運</div>
              <div class="word flex items-center justify-center">勢</div>
            </div>
            <div class="range">
              可查詢{{
                dayjs(months[0]).get("year") == dayjs().get("year")
                  ? "今年"
                  : "去年"
              }}{{ dayjs(months[0]).format("M") }}月~{{
                dayjs(months[months.length - 1]).get("year") ==
                dayjs().get("year")
                  ? "今年"
                  : "明年"
              }}{{ dayjs(months[months.length - 1]).format("M") }}月
            </div>
          </div>
          <div class="w-2/6 flex flex-col items-center">
            <div
              class="btn-filter good-month flex justify-center items-center"
              :class="{
                active: selectedType == 'good',
                inactive: selectedType == 'bad',
              }"
              @click="selectType('good')"
            >
              好運月
            </div>
            <div
              class="btn-filter bad-month flex justify-center items-center"
              :class="{
                active: selectedType == 'bad',
                inactive: selectedType == 'good',
              }"
              @click="selectType('bad')"
            >
              壞運月
            </div>
          </div>
        </div>
        <div class="bg-main">
          <div
            class="h-full flex flex-row flex-wrap items-center justify-center"
          >
            <div
              v-for="month in months"
              class="item-month"
              @click="selectMonth(month)"
            >
              <div
                class="age text-center"
                :class="{
                  currentMonth: month == currentMonth,
                  selectedMonth: month == selectedMonth,
                  inactive: isMonthInactive(month),
                }"
              >
                <span v-if="month == currentMonth">本月</span>
                <span v-else-if="dayjs(month).get('year') < dayjs().get('year')"
                  >去年</span
                >
                <span v-else-if="dayjs(month).get('year') > dayjs().get('year')"
                  >明年</span
                >
                <span class="text-transparent" v-else>今年</span>
              </div>
              <div
                class="month-ring flex items-center justify-center"
                :class="{
                  currentMonth: month == currentMonth,
                  selectedMonth: month == selectedMonth,
                }"
              >
                <div
                  class="month flex items-center justify-center"
                  :class="{
                    good: result[month].light == 't1',
                    bad: result[month].light == 't2',
                    inactive: isMonthInactive(month),
                    currentMonth: month == currentMonth,
                  }"
                >
                  {{ dayjs(month).format("M") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=""></div>
      </div>
    </div>
    <div
      v-if="cardUrl"
      class="
        h-full
        w-full
        absolute
        z-10
        top-0
        bg-black
        flex flex-col
        items-center
        justify-center
      "
    >
      <div class="rounded-full bg-white self-end mr-2" @click="closeImage()">
        <mdicon name="close" width="30" height="30" />
      </div>
      <div><img :src="cardUrl" alt="" /></div>
    </div>
    <notifyModal
      :popID="12"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-2.png'],
      }"
    ></notifyModal>
  </div>
</template>

<script>
import notifyModal from "@/components/notifyModal.vue";
import dayjs from "dayjs";
import Util from "@/mixins/util";
export default {
  name: "GoodluckMonth",
  mixins: [Util],
  components: { notifyModal },
  data() {
    return {
      ready: false,
      liffId: "1656896964-3njeeX5N",
      title: "",
      dayjs,
      selectedType: null,
      selectedMonth: null,
      userMonth: null,
      currentMonth: null,
      result: null,
      cardUrl: null,
      months: [],
    };
  },
  async created() {
    await this.setLiff(this.liffId);

    let months = [];
    let currentMonth = dayjs().format("YYYY-MM-01");

    months.push(currentMonth);
    for (let i = 1; i <= 2; i++) {
      months.unshift(
        dayjs()
          .add(-1 * i, "months")
          .format("YYYY-MM-01")
      );
    }
    for (let i = 1; i <= 12; i++) {
      months.push(dayjs().add(i, "months").format("YYYY-MM-01"));
    }
    this.months = months;
    this.currentMonth = currentMonth;
    this.selectedMonth = currentMonth;

    await this.init();
  },
  methods: {
    async init() {
      let data = await this.getData();
      let result = {};
      data.data.forEach((row) => {
        let format = `${row.date_ym.substring(0, 4)}-${row.date_ym.substring(
          4,
          6
        )}-01`;
        result[format] = row;
      });
      this.result = result;
      this.ready = true;
    },
    closeImage() {
      this.cardUrl = null;
    },
    async closeCheckbox() {
      this.hasNotified = true;
      if (this.checkbox) {
        this.setState(3);
      }
    },

    async getData() {
      let url = `${this.apiURL}/detailed_murphy/lineID/${this.$store.state.profile.userId}/month`;
      let data = await fetch(url);
      data = await data.json();
      return data;
    },
    selectType(type) {
      if (this.selectedType == type) {
        this.selectedType = null;
        return;
      }
      if (
        this.selectedMonth &&
        type == "good" &&
        this.result[this.selectedMonth].light == "t2"
      )
        this.selectedMonth = null;
      if (
        this.selectedMonth &&
        type == "bad" &&
        this.result[this.selectedMonth].light == "t1"
      )
        this.selectedMonth = null;
      this.selectedType = type;
    },
    selectMonth(month) {
      if (this.selectedType == "good" && this.result[month].light == "t2")
        return;
      if (this.selectedType == "bad" && this.result[month].light == "t1")
        return;
      this.selectedMonth = month;
      this.cardUrl = this.result[month].cardUrl;
    },
    isMonthInactive(month) {
      if (!this.selectedType) return false;
      if (this.selectedType == "good" && this.result[month].light == "t2")
        return true;
      if (this.selectedType == "bad" && this.result[month].light == "t1")
        return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-line {
  border: 1px solid red;
}
.search-block {
  padding: 12px;
  margin: 0px 20px;
  margin-bottom: 30px;
  border-radius: 30px;
  border: 0px solid #00ffff;
  box-shadow: 0px 0px 16px #00ffff;

  background-color: #1a1b22;
  .word {
    color: #00ffff;
    font-weight: bolder;
    border: 1px solid #00ffff;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    margin: 0 2px;
    font-size: 20px;
  }
  .range {
    color: #808080;
    padding-top: 10px;
    font-size: 16px;
  }
  .btn-filter {
    font-family: "Noto Sans TC", sans-serif !important;
    border-radius: 30px;
    padding: 5px 15px;
    margin: 8px;
    font-size: 14px;
    font-weight: 600;
    &.good-month {
      background-color: #fbe4dc;
      color: #f87171;
      margin-bottom: 2px;
      &.active {
        background-color: #cd6161;
        color: #fff;
      }
    }
    &.bad-month {
      background-color: #d7d7d7;
      color: #424242;
      &.active {
        background-color: #efefef;
        color: #000;
      }
    }
    &.inactive {
      background-color: #808080;
      color: #161820;
    }
  }
}

.bg-main {
  background-color: #1a1c22;
  padding: 10px 0px;
  .item-month {
    flex: 0 0 20%;
    margin: 18px 0px;
    .age {
      font-size: 17px;
      color: #808080;

      &.inactive {
        color: #333333;
      }
      &.currentMonth {
        color: #808080;
      }
      &.selectedMonth {
        color: #fff;
      }
    }
    .month-ring {
      margin: 0 auto;
      border: 2px solid #1a1c22;
      width: 62px;
      height: 62px;
      border-radius: 100%;
      &.currentMonth {
        border-color: #808080;
      }
      &.selectedMonth {
        border-color: #f15a24;
      }
    }
    .month {
      font-family: "Rubik", sans-serif !important;
      font-weight: 300;
      font-size: 26px;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      box-shadow: 2px 2px 2px 2px #000000de;

      &.good {
        color: #ef4444;
        background-color: #fbe4dc;
      }
      &.bad {
        color: #535353;
        background-color: #d7d7d7;
      }
      &.inactive {
        color: #333333;
        background-color: #1a1c22;
        &.currentMonth {
          color: #808080;
        }
      }
    }
  }
}
.cover {
  background-color: #00000073;
  .text-primary {
    color: #ed257b;
  }
  .text-secondary {
    color: #333;
  }
  .text-checkbox {
    color: #999999;
    input[type="checkbox"] {
      position: relative;
      top: 2px;
      width: 18px;
      height: 18px;
    }
  }
}
</style>